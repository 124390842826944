import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('send-msg',{directives:[{name:"show",rawName:"v-show",value:(_vm.actived === 3),expression:"actived === 3"}],attrs:{"actived":_vm.actived,"mobile":_vm.cMobile},on:{"update:actived":function($event){_vm.actived=$event}}}),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.actived !== 3),expression:"actived !== 3"}],staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c(VForm,{staticClass:"multi-col-validation"},[_c(VCardText,{staticClass:"pt-5"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c('p',{staticClass:"text--primary mt-2 mb-2"},[_vm._v(" 账单确认人手机号 ")])]),_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":[_vm.validators.required, _vm.validators.mobileValidator],"outlined":"","dense":""},model:{value:(_vm.cMobile),callback:function ($$v) {_vm.cMobile=$$v},expression:"cMobile"}})],1)],1)],1)],1),_c(VCardText,[_c(VBtn,{staticClass:"me-3 mt-3",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }