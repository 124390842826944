import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-3 mt-2 ml-lg-10",attrs:{"flat":""}},[_c(VCardText,{staticClass:"d-flex"},[_c(VAvatar,{staticClass:"me-6",attrs:{"size":"120"}},[_c(VImg,{attrs:{"src":_vm.submitData.avatar || require('@/assets/images/avatars/1.png')}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c(VChip,{attrs:{"color":_vm.genResolverFromOptions(_vm.authStatusOptions, 'color')(_vm.getParsedUserData(_vm.userInfo).authStatus)}},[_vm._v(" "+_vm._s(_vm.genResolverFromOptions(_vm.authStatusOptions, 'text')(_vm.getParsedUserData(_vm.userInfo).authStatus))+" ")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"multi-col-validation mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('input-group',{attrs:{"input-group-list":_vm.formComponents,"row-class":"ma-0 pa-0"},model:{value:(_vm.submitData),callback:function ($$v) {_vm.submitData=$$v},expression:"submitData"}}),_c(VRow)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }